export const eventsData = [
  [
    {
      name: 'Realm of Tempered Valor',
      pos: '0% 30%',
      image: 'Realm of Tempered Valor.png',
      start: '2025-02-24 10:00:00',
      end: '2025-03-10 03:59:59',
      color: '#e6e4c4',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/37225915',
      showOnHome: true,
    },
    {
      name: 'Ley Line Overflow',
      pos: '0% 20%',
      image: 'leyline_overflow.jpg',
      start: '2025-03-17 04:00:00',
      end: '2025-03-24 03:59:59',
      color: '#5baced',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/37319302',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Enchanted Tales of the Mikawa Festival',
      pos: '0% 20%',
      image: 'Enchanted Tales of the Mikawa Festival.png',
      start: '2025-02-14 10:00:00',
      end: '2025-03-03 03:59:59',
      color: '#ffd8ee',
      zoom: '100%',
      url: 'https://www.hoyolab.com/article/37008951',
      showOnHome: true,
    },
    {
      name: 'Reel Ad-Venture',
      pos: '0% 40%',
      image: 'Reel Ad-Venture tmp.png',
      start: '2025-03-12 10:00:00',
      end: '2025-03-24 03:59:59',
      color: '#fff358',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/37319302',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Tapirus Somniator Chapter',
      pos: '0% 20%',
      image: 'Tapirus Somniator Chapter.png',
      start: '2025-02-12 06:00:00',
      end: '2025-03-04 17:59:59',
      color: '#13a5ff',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/36971026',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Invasive Fish Wrangler',
      pos: '0% 40%',
      image: 'Invasive Fish Wrangler.png',
      start: '2025-03-05 10:00:00',
      end: '2025-03-17 03:59:59',
      color: '#00b1e1',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/37397966',
      showOnHome: true,
    },
  ],
  [
    {
      name: "Travelers' Tales: Anthology Chapter",
      pos: '0% 40%',
      image: 'Travelers Tales Anthology Chapter.png',
      start: '2025-02-12 06:00:00',
      end: '2025-03-25 14:59:59',
      color: '#f0e873',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/36971022',
      showOnHome: true,
      timezoneDependent: true,
    },
  ],
  [
    {
      name: "Dawn's Drifting Reverie - Yumemizuki Mizuki Banner",
      pos: '30% 15%',
      zoom: '200%',
      image: 'Dawns Drifting Reverie 1.png',
      start: '2025-02-12 06:00:00',
      end: '2025-03-04 17:59:00',
      color: '#d8f0ff',
      url: 'https://www.hoyolab.com/article/36971021',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Tempestuous Destiny - Wriothesley Banner',
      pos: '30% 15%',
      zoom: '200%',
      image: 'Tempestuous Destiny 2.png',
      start: '2025-03-04 18:00:00',
      end: '2025-03-25 14:59:00',
      color: '#b3d7f1',
      url: 'https://www.hoyolab.com/article/37319180',
      showOnHome: true,
    },
    {
      name: 'Cornucopia of Contention - Varesa Banner',
      pos: '30% 17%',
      zoom: '200%',
      image: 'Cornucopia of Contention 1.png',
      start: '2025-03-26 06:00:00',
      end: '2025-04-15 17:59:00',
      color: '#ffa3af',
      url: 'https://www.hoyolab.com/article/37812874',
      showOnHome: true,
      timezoneDependent: true,
    },
  ],
  [
    {
      name: 'Romaritime Meluserenity - Sigewinne Banner',
      pos: '30% 22%',
      zoom: '200%',
      image: 'Romaritime Meluserenity 2.png',
      start: '2025-02-12 06:00:00',
      end: '2025-03-04 17:59:00',
      color: '#fadbf8',
      url: 'https://www.hoyolab.com/article/36971021',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Chanson of Many Waters - Furina Banner',
      pos: '30% 15%',
      zoom: '200%',
      image: 'Chanson of Many Waters 3.png',
      start: '2025-03-04 18:00:00',
      end: '2025-03-25 14:59:00',
      color: '#95d6fb',
      url: 'https://www.hoyolab.com/article/37319180',
      showOnHome: true,
    },
    {
      name: 'The Crane Soars Skyward - Xianyun Banner',
      pos: '30% 17%',
      zoom: '200%',
      image: 'The Crane Soars Skyward 2.png',
      start: '2025-03-26 06:00:00',
      end: '2025-04-15 17:59:00',
      color: '#affcd1',
      url: 'https://www.hoyolab.com/article/37812874',
      showOnHome: true,
      timezoneDependent: true,
    },
  ],
  [
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 45%',
      zoom: '200%',
      image: 'Epitome Invocation 77.png',
      start: '2025-02-12 06:00:00',
      end: '2025-03-04 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/36971021',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 45%',
      zoom: '200%',
      image: 'Epitome Invocation 78.png',
      start: '2025-03-04 18:00:00',
      end: '2025-03-25 14:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/37319180',
      showOnHome: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 45%',
      zoom: '150%',
      image: 'Epitome Invocation 79.png',
      start: '2025-03-26 06:00:00',
      end: '2025-04-15 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/37812874',
      showOnHome: true,
      timezoneDependent: true,
    },
  ],
  [
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2025-01-16 04:00:00',
      end: '2025-02-16 04:00:00',
      color: '#4299E1',
      description:
        "After a party member triggers a Pyro-related reaction, a shockwave will be unleashed at the character's position, dealing True DMG to nearby opponents. This effect can be triggered once every 3s.",
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2025-02-16 04:00:00',
      end: '2025-03-16 04:00:00',
      color: '#4299E1',
      description:
        "When a character receives healing, a shockwave will be unleashed at the character's position, dealing True DMG to nearby opponents. This effect can be triggered once every 3s.",
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2025-03-16 04:00:00',
      end: '2025-04-16 04:00:00',
      color: '#4299E1',
      description:
        "When a character's Plunging Attacks hit opponents, a shockwave will be unleashed at the character's position, dealing True DMG to nearby opponents. This effect can be triggered once every 3s.",
    },
  ],
  [
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2025-02-01 04:00:00',
      end: '2025-03-01 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Pyro, Hydro, and Anemo. Opening Characters: Hu Tao, Gaming, Mona, Xingqiu, Venti, and Shikanoin Heizou',
    },
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2025-03-01 04:00:00',
      end: '2025-04-01 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Hydro, Electro, and Cryo. Opening Characters: Kamisato Ayato, Barbara, Raiden Shogun, Ororon, Eula, and Layla',
    },
  ],
  [
    {
      name: 'Battle Pass - Wondrous Reverie',
      pos: '0% 45%',
      image: 'Wondrous Reverie.jpg',
      start: '2025-02-12 06:00:00',
      end: '2025-03-24 03:59:59',
      color: '#ffb95e',
      url: 'https://www.hoyolab.com/article/35949127',
      timezoneDependent: true,
    },
    {
      name: 'Battle Pass - Floral Odyssey',
      pos: '0% 45%',
      image: 'Floral Odyssey.jpg',
      start: '2025-03-26 06:00:00',
      end: '2025-05-05 03:59:59',
      color: '#d9b461',
      url: 'https://www.hoyolab.com/article/37812971',
      timezoneDependent: true,
    },
  ],
];
